<template>
  <div :class="'page'">
    
    <div :class="['form', $style.wrapper]">
      <div :class="$style.searchInputWrapper">
        <div :class="$style.inputWrapper">
          <img :class="$style['pinliteLogo']" :src="`${process.env.CDN_DZ_PATH}/p/pinilte_logo_w.svg`">
          <div :class="$style.inputBox">
            <input placeholder="即时搜索Pinterest图片" v-touch:tap="()=>handleClickInput()" :class="$style.input"/>
          </div>
        </div>
      </div>
      <div class="row row-center">
        <img  :class="$style.img404" :src="`${process.env.CDN_DZ_PATH}/p/404.svg`" />
      </div>
      <div class="row">
        <p :class="$style.info">哎呀，出错了，你访问的页面不存在。</p>
      </div>
      <div class="row row-center">
        <button @click="handleGoHome" :class="$style.btn" :full="false" >返回首页</button>
      </div>
      <div :class="['row', 'row-center', $style.qrWrapper]">
        <img :src="`${process.env.CDN_DZ_PATH}/bbw/qr-pinlite-404.png`" alt="" :class="$style.qrcode">
        <div :class="$style.help">
          扫码添加大作客服
          <br/>
          我们将为您及时提供帮助~
        </div>
      </div>
    </div>
  </div>
</template>
<script lang=ts>
    import {Button} from "bbw-m-components/components";
    import { util } from "bbw-m-components/scripts"
    import {
        emptyImg,
        isPinlite,
        isSearchMobile,
        PageMobileHome,
        PageSearchIndex,
        routerPush,
        ServerMobileServer
    } from "bbw-common";
    export default {
        name:"Page404",
        components: { Button },
        methods:{
            handleGoHome(){
                routerPush( this.$router,{ path: "/tupian" } )
            },
            /** 点击搜索框 */
            handleClickInput(){
                routerPush( this.$router, {path: util.pinliteRoot() + "/search" } )
            },
        },
        computed:{
            qrsrc(){
                if( this.$store.state.server ) return emptyImg;
                return isSearchMobile() ? `${process.env.CDN_DZ_PATH}/bbw/qr-m-404.png`: `${process.env.CDN_DZ_PATH}/bbw/qr-pinlite-404.png`
            }
        }
    }
</script>
<style lang=scss module src="./404.scss"></style>
