var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "page" }, [
    _c("div", { class: ["form", _vm.$style.wrapper] }, [
      _c("div", { class: _vm.$style.searchInputWrapper }, [
        _c("div", { class: _vm.$style.inputWrapper }, [
          _c("img", {
            class: _vm.$style["pinliteLogo"],
            attrs: {
              src: _vm.process.env.CDN_DZ_PATH + "/p/pinilte_logo_w.svg",
            },
          }),
          _vm._v(" "),
          _c("div", { class: _vm.$style.inputBox }, [
            _c("input", {
              directives: [
                {
                  name: "touch",
                  rawName: "v-touch:tap",
                  value: function () {
                    return _vm.handleClickInput()
                  },
                  expression: "()=>handleClickInput()",
                  arg: "tap",
                },
              ],
              class: _vm.$style.input,
              attrs: { placeholder: "即时搜索Pinterest图片" },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row row-center" }, [
        _c("img", {
          class: _vm.$style.img404,
          attrs: { src: _vm.process.env.CDN_DZ_PATH + "/p/404.svg" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("p", { class: _vm.$style.info }, [
          _vm._v("哎呀，出错了，你访问的页面不存在。"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row row-center" }, [
        _c(
          "button",
          {
            class: _vm.$style.btn,
            attrs: { full: false },
            on: { click: _vm.handleGoHome },
          },
          [_vm._v("返回首页")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: ["row", "row-center", _vm.$style.qrWrapper] }, [
        _c("img", {
          class: _vm.$style.qrcode,
          attrs: {
            src: _vm.process.env.CDN_DZ_PATH + "/bbw/qr-pinlite-404.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { class: _vm.$style.help }, [
          _vm._v("\n        扫码添加大作客服\n        "),
          _c("br"),
          _vm._v("\n        我们将为您及时提供帮助~\n      "),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }