var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "touch",
          rawName: "v-touch:tap.stop",
          value: function () {
            _vm.handleClick()
          },
          expression: "()=>{ handleClick() }",
          arg: "tap",
          modifiers: { stop: true },
        },
      ],
      class: [
        _vm.$style.button,
        _vm.$style[_vm.type],
        _vm.full ? _vm.$style.full : _vm.$style.content,
        _vm.radiusClass,
        _vm.$style[_vm.colorTheme],
      ],
      attrs: { disable: _vm.disable },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }