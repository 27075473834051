<template>
  <div
      v-touch:tap.stop="()=>{ handleClick() }"
      :class="[$style.button,$style[type], full?$style.full:$style.content, radiusClass, $style[colorTheme] ]"
      :disable=disable>
    <slot></slot>
  </div>
</template>
<script lang=ts>
  export default {
      name:"Button",
      props:{
          /** 排版样式
           *  normal:       登录注册用按钮
           *  bbw:          大作按钮只有文字或按钮
           *  bbwIconOnly:  大作按钮只有icon
           *  normal2       登录注册用按钮v2
           */
          type:{
              type:String,
              required:false,
              default:'normal'
          },
          /** 颜色主题
           *  primary:      大作红底色，白字
           *  textOnly:     白底, 文字#AAA
           *  white:        白底，文字#666
           *  white-border: 白底，文字#666，有描边
           */
          colorTheme:{
              type: String,
              required: false,
              default: 'primary'
          },
          /** 圆角角度 */
          radius:{
              type:Number,
              required:false,
              default: 38
          },
          /** 是否禁用 */
          disable:{
              type:Boolean,
              required:false,
              default:false
          },
          /** 是否填满宽度 */
          full:{
              type:Boolean,
              required:false,
              default:true
          }
      },
      methods:{
          handleClick(){
              if( this.disable ) return;
              this.$emit('click');
          },
      },
      computed:{
          radiusClass(){
              return this.$style[`radius${this.radius}`]
          }
      }
  }
</script>
<style lang=scss module src="./button.scss"></style>
